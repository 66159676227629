import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import homeLogo from '../../Assets/home-main.svg';
import Particle from '../Particle';
import Introduction from './Introduction';
import Type from './Type';
import Techstack from '../Techstack';
import Toolstack from '../Toolstack';
import Projects from '../Projects/Projects';

function Home() {
	return (
		<section>
			<Container fluid className="home-section" id="home">
				<Particle />
				<Container className="home-content">
					<Row>
						<Col md={7} className="home-header">
							<h1 style={{ paddingBottom: 15 }} className="heading">
								Hi There!{' '}
								<span className="wave" role="img" aria-labelledby="wave">
									👋🏻
								</span>
							</h1>

							<h1 className="heading-name">
								I am
								<strong className="main-name"> LÊ MẠNH LINH</strong>
							</h1>

							<div style={{ padding: 45, textAlign: 'left' }}>
								<Type />
							</div>
						</Col>

						<Col md={5} style={{ paddingBottom: 20 }}>
							<img src={homeLogo} alt="home pic" className="img-fluid" style={{ maxHeight: '450px' }} />
						</Col>
					</Row>
				</Container>
			</Container>
			<Introduction />
			<Projects />
			<Container className="home-content">
				<Techstack />
				<Toolstack />
			</Container>
		</section>
	);
}

export default Home;
