import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Particle from '../Particle';
import Github from './Github';
import Techstack from '../Techstack';
import Aboutcard from './AboutCard';
import laptopImg from '../../Assets/about.png';
import Toolstack from '../Toolstack';

function About() {
	return (
		<Container fluid className="about-section">
			<Particle />
			<Container>
				<Row>
					<Col sm={6} className="align-content-center">
						<h1 className="flex-grow-1">
							Know Who <strong className="purple">I am</strong>
						</h1>
					</Col>
					<Col sm={6}>
						<img src={laptopImg} alt="about" className="img-fluid" />
					</Col>
				</Row>
				<Aboutcard />

				<Techstack />

				<Toolstack />

				<Github />
			</Container>
		</Container>
	);
}

export default About;
