import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProjectCard from './ProjectCards';
import Particle from '../Particle';
import pupi from '../../Assets/Projects/pupi.png';
import runnow from '../../Assets/Projects/runnow.png';
import metaspace from '../../Assets/Projects/metaspace.png';
import doctruyen from '../../Assets/Projects/doctruyen.png';

const DATA = [
	{
		imgPath: metaspace,
		company: 'KBG Studios',
		title: 'Mobile Developer',
		project: 'Metaspace Application',
		subProject: 'A SocialFi super app integrating many features within.',
		time: 'December 2023 - Present',
		features: [
			'Implemented authentication and social login integrations (Facebook, Gmail, Apple).',
			'Developed user profile management, including features for following other users and profile switching.',
			'Created functionalities for finding dating partners based on location.',
			'Integrated media and date-time pickers to enhance user interaction.'
		],
		technologies: [
			'Developed applications using React Native for cross-platform compatibility.',
			'Utilized axios for handling HTTP requests and Redux for state management.',
			'Implemented navigation with React Navigation and enhanced user interactions with Reanimated and Gesture Handler.',
			'Leveraged React Hook Form for efficient form handling and GeoLocation for location-based services.'
		],
		tools: 'GitLab & Lark'
	},
	{
		imgPath: runnow,
		company: 'KBG Studios',
		title: 'Fullstack Developer',
		project: 'RunNow Application',
		subProject: 'A web3 Lifestyle App.',
		time: 'August 2023 - Present',
		description:
			'Runnow.io SuperApp covers from casual workout activities like Walking, Cycling, Swimming or intensive training like Boxing, Yoga, Gym & Fitness, to the normal daily life activities such as Shopping, Learning, Gaming, Charity.',
		features: [
			{
				'Frontend (FE):':
					'Implemented deep linking, push notifications, navigation, animations, authentication, social login (Facebook, Gmail, Apple), user profiles, media picker, location services, date-time picker, etc.'
			},
			{ 'Backend (BE):': ' Integrated JWT authentication, profile updates, club management, etc.' },
			{ 'Deployment:': 'Successfully uploaded applications to both the App Store and Google Play Store.' }
		],
		technologies: [
			{
				'Frontend (FE):':
					'Leveraged React Native, Redux for state management, WebSockets for real-time communication, axios for API calls, Firebase for push notifications, Reanimated for smooth animations, Firebase for various functionalities, GeoLocation services, Navigation for routing, React Hook Form for form handling, etc.'
			},
			{
				'Backend (BE):':
					'Utilized Node.js for server-side logic, Express.js for web application framework, MongoDB for database management, axios for HTTP requests.'
			}
		],
		tools: 'GitLab & Lark'
	},
	{
		imgPath: pupi,
		company: 'ABC Technology JSC',
		title: 'Mobile Developer',
		project: 'Pupi Application',
		subProject: 'Học vui & tiến bộ',
		time: 'August 2022 - July 2023',
		features: [
			'Designed and implemented user profiles with capabilities for updating and managing profiles seamlessly.',
			'Developed a countdown timer feature and implemented robust filtering and sorting functionalities.',
			'Integrated animations to enhance user interface interactions. ',
			'Connected WebSocket technology to enable real-time communication across multiple players. ',
			'Implemented media picker and push notification functionalities to enrich user engagement.'
		],
		technologies: [
			'Developed applications using React Native for cross-platform compatibility.',
			'Utilized axios for efficient HTTP requests and Firebase for real-time database and authentication.',
			'Enhanced user interactions with Reanimated and Gesture Handler.',
			'Implemented efficient form handling with React Hook Form.'
		],
		tools: 'GitLab & Trello',
		appleStore: 'https://apps.apple.com/us/app/pupi-h%E1%BB%8Dc-vui-ti%E1%BA%BFn-b%E1%BB%99/id1638474798'
	},
	{
		imgPath: doctruyen,
		company: 'Personal project',
		title: 'Fullstack Developer',
		project: 'Đọc Truyện Application',
		time: 'August 2023 - Feb 2024',
		description:
			'Đọc Truyện is an app designed for young readers to enjoy various genres such as martial arts, fantasy, action, etc...',
		features: [
			[
				'Integrated GG AdMob for ad management.',
				'Used Reanimated and Animated for dynamic UI animations.',
				'Implemented filtering, sorting, and a slider component.',
				'State management with Zustand + MMKV for local data storage.'
			],
			[
				'Deployed NodeJS applications on a VPS server.',
				' Used Axios for HTTP requests and MongoDB for database management.',
				' Implemented secure user authentication with bcrypt and managed access tokens.',
				' Integrated email sending functionality'
			],
			['Developed Python scripts for data crawling and stored data in MongoDB.']
		],
		technologies: [
			{ 'Frontend (FE):': 'React Native, Reanimated, GG AdMob, Zustand + MMKV.' },
			{ 'Backend (BE):': 'NodeJS, Express JS, MongoDB, Axios.' },
			{ 'Server:': 'VPS server, PM2, Nginx.' },
			{ 'Data:': 'Python, MongoDB.' }
		],
		tools: 'GitHub & Trello',
		chPlay: 'https://play.google.com/store/apps/details?id=com.doctruyen',
		ghLinkFE: 'https://github.com/LinhLM23496/Frontend-Doctruyen',
		ghLinkBE: 'https://github.com/LinhLM23496/Backend-DocTruyen'
	}
];

function Projects() {
	const renderItem = (item, index) => {
		return (
			<Col lg={6} md={12} sm={12} className="project-card">
				<ProjectCard {...item} />
			</Col>
		);
	};
	return (
		<Container fluid className="project-section">
			<Particle />
			<Container>
				<h1 className="project-heading">
					My Recent <strong className="purple">Works </strong>
				</h1>
				<p style={{ color: 'white' }}>Here are a few projects I have worked on recently.</p>
				<Row style={{ justifyContent: 'center', paddingBottom: '10px' }}>{DATA.map(renderItem)}</Row>
			</Container>
		</Container>
	);
}

export default Projects;
