import React from 'react';
import Card from 'react-bootstrap/Card';
import { ImPointRight } from 'react-icons/im';

function AboutCard() {
	return (
		<Card className="quote-card-view">
			<Card.Body>
				<blockquote className="blockquote mb-0">
					<h4 style={{ textAlign: 'left' }}>Career objecttives:</h4>
					<ul>
						<li className="about-activity">
							<p style={{ fontSize: '16px' }}>
								Long-term goal:{' '}
								<span className="fst-italic">
									To become a reliable and successful full-stack mobile developer, contributing to the development of
									exceptional mobile applications and delivering outstanding user experiences across mobile platforms.
								</span>
							</p>
						</li>
						<li className="about-activity">
							<p style={{ fontSize: '16px' }}>Short-term goals: </p>
							<ul>
								<li>
									<span style={{ fontSize: '16px' }} className="fst-italic">
										To become a reliable and successful full-stack mobile developer, contributing to the development of
										exceptional mobile applications and delivering outstanding user experiences across mobile platforms.
									</span>
								</li>
								<li>
									<span style={{ fontSize: '16px' }} className="fst-italic">
										Cultivate a UX/UI design mindset, gaining a deep understanding of user interface design principles
										and user experience.
									</span>
								</li>
								<li>
									<span style={{ fontSize: '16px' }} className="fst-italic">
										Become a full-stack mobile developer, capable of working on both the frontend and backend aspects of
										mobile applications.
									</span>
								</li>
							</ul>
						</li>
					</ul>

					<h4 style={{ textAlign: 'left' }}>Certifications:</h4>
					<ul>
						<li>
							<p style={{ fontSize: '16px', textAlign: 'left' }} className="fst-italic">
								Fullstack Website Developer - FUNiX Education
							</p>
						</li>
					</ul>

					<h4 style={{ textAlign: 'left' }}>Interests:</h4>
					<ul>
						<li>
							<p style={{ fontSize: '16px', textAlign: 'left' }} className="fst-italic">
								Play game
							</p>
						</li>
						<li>
							<p style={{ fontSize: '16px', textAlign: 'left' }} className="fst-italic">
								Football
							</p>
						</li>
					</ul>
				</blockquote>
			</Card.Body>
		</Card>
	);
}

export default AboutCard;
