import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DiJavascript1, DiReact, DiNodejs, DiMongodb, DiGit } from 'react-icons/di';
import { SiFirebase, SiNextdotjs } from 'react-icons/si';
import { TbBrandReactNative } from 'react-icons/tb';
import { SiRedux } from 'react-icons/si';

const DATA = [
	{ Icon: <DiJavascript1 /> },
	{ Icon: <DiNodejs /> },
	{ Icon: <DiReact /> },
	{ Icon: <DiMongodb /> },
	{ Icon: <SiNextdotjs /> },
	{ Icon: <DiGit /> },
	{ Icon: <SiFirebase /> },
	{ Icon: <TbBrandReactNative /> },
	{ Icon: <SiRedux /> }
];

function Techstack() {
	const renderItem = ({ Icon }, index) => {
		return (
			<Col key={index} xs={4} md={2} className="tech-icons">
				{Icon}
			</Col>
		);
	};

	return (
		<div>
			<h1 className="project-heading text-center">
				Professional <strong className="purple">Skillset </strong>
			</h1>
			<Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>{DATA.map(renderItem)}</Row>
		</div>
	);
}

export default Techstack;
