import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import myImg from '../../Assets/avatar.svg';
import Tilt from 'react-parallax-tilt';

function Introduction() {
	return (
		<Container fluid className="home-about-section" id="about">
			<Container>
				<Row>
					<Col md={8} className="home-about-description">
						<h1 style={{ fontSize: '2.6em' }}>
							LET ME <span className="purple"> INTRODUCE </span> MYSELF
						</h1>
						<p className="home-about-body">
							I fell in love with programming and have at least learned something, I think… 🤷‍♂️
							<br />
							<br />I am fluent in classics like
							<i>
								<b className="purple"> Javascript and Typescript.</b>
							</i>
							<br />
							<br />
							My field of interest includes building new{' '}
							<i>
								<b className="purple">Web Technologies, Applications and Products</b>, as well as areas related to{' '}
								<b className="purple">Blockchain.</b>
							</i>
							<br />
							<br />
							Whenever possible, I also apply my passion for developing products using <b className="purple">
								Node.js
							</b>{' '}
							and
							<i>
								<b className="purple"> Modern Javascript Library and Frameworks</b>
							</i>{' '}
							like
							<i>
								<b className="purple"> ReactJS, NextJS and React Native.</b>
							</i>
						</p>
					</Col>
					<Col md={4} className="myAvtar">
						<Tilt>
							<img src={myImg} className="img-fluid" alt="avatar" />
						</Tilt>
					</Col>
				</Row>
			</Container>
		</Container>
	);
}
export default Introduction;
